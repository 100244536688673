import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../api/axios';
import { API_BASE_URL } from 'config';

const initialState = {
  active: '',
  name: '',
  data: [],
  isError: false,
  isLoading: false,
  isRefetching: false,
  rowCount: 0,
  pagination: {
    pageIndex: 0,
    pageSize: 5
  },
  users: [],
  isLoadingUsers: false,
  openAddXa: false,
  addXaLoading: false,
  openUpdateXa: false,
  updateXaLoading: false,
  initXaValue: null,
  deleteXaLoading: false,
  openAddManager: false,
  isLoadingAddManager: false
};

export const getAreaByActive = createAsyncThunk('areaDetail/getAreaByActive', async (active) => {
  try {
    const response = await axios.get('/areas/' + active);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getXaByArea = createAsyncThunk('areaDetail/getXaByArea', async ({ active, pagination }) => {
  const { pageIndex, pageSize } = pagination;
  const url = new URL('/api/v1/xas', API_BASE_URL);
  url.searchParams.set('area', active);
  url.searchParams.set('page', `${pageIndex + 1}`);
  url.searchParams.set('perPage', `${pageSize}`);
  try {
    const response = await axios.get(url.href);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getUserByArea = createAsyncThunk('areaDetail/getUserByArea', async ({ active }) => {
  const url = new URL('/api/v1/users', API_BASE_URL);
  url.searchParams.set('area', active);
  try {
    const response = await axios.get(url.href);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const addXa = createAsyncThunk('areaDetail/addXa', async (data) => {
  try {
    const result = await axios.post('/xas', data);
    if (result) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const updateXa = createAsyncThunk('areaDetail/updateXa', async (data) => {
  try {
    const result = await axios.put(`/xas/${data.active}`, { name: data.name });
    if (result) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const deleteXa = createAsyncThunk('areaDetail/deleteXa', async (deleteActive) => {
  try {
    const result = await axios.delete(`/xas/${deleteActive}`);
    if (result) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const addManager = createAsyncThunk('areaDetail/addManager', async (data, { rejectWithValue }) => {
  try {
    const response = await axios.post('/users/add_manager', data);
    if (response) {
      return response.data;
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      return rejectWithValue(error.response.data);
    } else {
      console.error(error);
      throw error;
    }
  }
});

export const deleteManager = createAsyncThunk('areaDetail/deleteManager', async (data) => {
  try {
    const response = await axios.delete('/users/delete/manager', { data });
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
});

const areaDetailSlice = createSlice({
  name: 'areaDetail',
  initialState,
  reducers: {
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setOpenAddXa: (state, action) => {
      state.openAddXa = action.payload;
    },
    setOpenUpdateXa: (state, action) => {
      state.openUpdateXa = action.payload;
    },
    setInitXaValue: (state, action) => {
      state.initXaValue = action.payload;
    },
    setOpenAddManager: (state, action) => {
      state.openAddManager = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAreaByActive.fulfilled, (state, action) => {
        state.active = action.payload.data.active;
        state.name = action.payload.data.name;
      })
      .addCase(getXaByArea.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getXaByArea.fulfilled, (state, action) => {
        const result = action.payload.data;
        state.data = result.result;
        state.rowCount = result.meta.total;
        state.isLoading = false;
        state.isRefetching = false;
        state.isError = false;
      })
      .addCase(getXaByArea.rejected, (state) => {
        state.isLoading = false;
        state.isRefetching = false;
        state.isError = true;
      })
      .addCase(getUserByArea.pending, (state) => {
        state.isLoadingUsers = true;
      })
      .addCase(getUserByArea.fulfilled, (state, action) => {
        const result = action.payload.data;
        state.users = result.result;
        state.isLoadingUsers = false;
      })
      .addCase(getUserByArea.rejected, (state) => {
        state.isLoadingUsers = false;
      })
      .addCase(addXa.pending, (state) => {
        state.addXaLoading = true;
      })
      .addCase(addXa.fulfilled, (state) => {
        state.addXaLoading = false;
      })
      .addCase(addXa.rejected, (state) => {
        state.addXaLoading = false;
      })
      .addCase(updateXa.pending, (state) => {
        state.updateXaLoading = true;
      })
      .addCase(updateXa.fulfilled, (state) => {
        state.updateXaLoading = false;
      })
      .addCase(updateXa.rejected, (state) => {
        state.updateXaLoading = false;
      })
      .addCase(deleteXa.pending, (state) => {
        state.deleteXaLoading = true;
      })
      .addCase(deleteXa.fulfilled, (state) => {
        state.deleteXaLoading = false;
      })
      .addCase(deleteXa.rejected, (state) => {
        state.deleteXaLoading = false;
      })
      .addCase(addManager.pending, (state) => {
        state.isLoadingAddManager = true;
      })
      .addCase(addManager.fulfilled, (state) => {
        state.isLoadingAddManager = false;
      })
      .addCase(addManager.rejected, (state) => {
        state.isLoadingAddManager = false;
      });
  }
});

export const { setPagination, setLoading, setOpenAddXa, setOpenUpdateXa, setInitXaValue, setOpenAddManager } = areaDetailSlice.actions;

export default areaDetailSlice.reducer;
