import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../api/axios';
import { API_BASE_URL } from 'config';

const initialState = {
  areas: [],
  isLoadingAreas: false,
  query: ''
};

export const getAreas = createAsyncThunk('areas/getAreas', async ({ query }) => {
  const url = new URL('/api/v1/areas', API_BASE_URL);
  url.searchParams.set('query', `${query}`);
  url.searchParams.set('all', `${true}`);
  try {
    const response = await axios.get(url.href);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

const areaSlice = createSlice({
  name: 'area',
  initialState,
  reducers: {
    setQuery: (state, action) => {
      state.query = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAreas.pending, (state) => {
        state.isLoadingAreas = true;
      })
      .addCase(getAreas.fulfilled, (state, action) => {
        state.areas = action.payload.data.result;
        state.isLoadingAreas = false;
      })
      .addCase(getAreas.rejected, (state) => {
        state.isLoadingAreas = false;
      });
  }
});

export const { setQuery } = areaSlice.actions;

export default areaSlice.reducer;
