import { Stack } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const options = {
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`
};

const PdfComponent = ({ fileUrl }) => {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef();

  useEffect(() => {
    setContainerWidth(containerRef.current.getBoundingClientRect().width);
    const handleResize = () => setContainerWidth(containerRef.current.getBoundingClientRect().width);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function goToPrevPage() {
    setPageNumber(pageNumber - 1);
  }

  function goToNextPage() {
    setPageNumber(pageNumber + 1);
  }

  return (
    <div ref={containerRef}>
      <Document options={options} file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} width={containerWidth} />
      </Document>
      <Stack direction="row" justifyContent="space-between">
        <span>
          Trang {pageNumber} của {numPages}
        </span>
        <div>
          <button onClick={goToPrevPage} disabled={pageNumber <= 1}>
            Trước
          </button>
          <button onClick={goToNextPage} disabled={pageNumber >= numPages}>
            Sau
          </button>
        </div>
      </Stack>
    </div>
  );
};

export default PdfComponent;
