import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../api/axios';
import { API_BASE_URL } from 'config';

const initialState = {
  data: [],
  globalFilter: '',
  columnFilters: [],
  sorting: [],
  isError: false,
  isLoading: false,
  isRefetching: false,
  rowCount: 0,
  pagination: {
    pageIndex: 0,
    pageSize: 10
  },
  areas: [],
  area_active: '',
  xas: [],
  xa_active: '',
  tuviens: [],
  tuvien_active: '',
  isTuVienLoading: false,
  tuvien: null,
  tkgioipham: [],
  isTKGioiPhamLoading: false,
  tkgiaopham: [],
  isTKGiaoPhamLoading: false,
  tkhocvi: [],
  isTKHocViLoading: false
};

export const getAreas = createAsyncThunk('search/getAreas', async () => {
  const url = new URL('/api/v1/areas', API_BASE_URL);
  url.searchParams.set('all', `${true}`);
  try {
    const response = await axios.get(url.href);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getXaByAreaActive = createAsyncThunk('search/getXaByAreaActive', async ({ area_active }) => {
  const url = new URL('/api/v1/xas', API_BASE_URL);
  url.searchParams.set('area', area_active);
  url.searchParams.set('all', `${true}`);
  try {
    const response = await axios.get(url.href);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getTuViensByXaActive = createAsyncThunk('search/getTuViens', async ({ xa_active }) => {
  const url = new URL('/api/v1/tuviens', API_BASE_URL);
  url.searchParams.set('all', 'true');
  url.searchParams.set('xa', xa_active);
  try {
    const response = await axios.get(url.href);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getTuVienByActive = createAsyncThunk('search/getTuVienByActive', async (active) => {
  try {
    const response = await axios.get(`/tuviens/${active}/search`);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getMonksByTuVien = createAsyncThunk(
  'search/getMonksByTuVien',
  async ({ globalFilter, pagination, sorting, columnFilters, tu_vien_active }) => {
    const { pageIndex, pageSize } = pagination;
    const url = new URL('/api/v1/tangnis', API_BASE_URL);
    url.searchParams.set('page', `${pageIndex + 1}`);
    url.searchParams.set('perPage', `${pageSize}`);
    url.searchParams.set('tuvien', `${tu_vien_active}`);
    url.searchParams.set('query', globalFilter ?? '');
    url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
    url.searchParams.set('sortBy', `${sorting.length !== 0 ? sorting[0].id : ''}`);
    url.searchParams.set('sortOrder', `${sorting.length !== 0 ? (sorting[0].desc ? 'desc' : 'asc') : ''}`);
    try {
      const response = await axios.get(url.href);
      if (response) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getTKGioiPham = createAsyncThunk('search/getTKGioiPham', async (active) => {
  try {
    const response = await axios.get(`/tangnis/${active}/tkgioi_pham`);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getTKGiaoPham = createAsyncThunk('search/getTKGiaoPham', async (active) => {
  try {
    const response = await axios.get(`/tangnis/${active}/tkgiao_pham`);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getTKHocVi = createAsyncThunk('search/getTKHocVi', async (active) => {
  try {
    const response = await axios.get(`/tangnis/${active}/tkhoc_vi`);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setAreaActive: (state, action) => {
      state.area_active = action.payload;
    },
    setXaActive: (state, action) => {
      state.xa_active = action.payload;
    },
    setTuVienActive: (state, action) => {
      state.tuvien_active = action.payload;
    },
    setTuVien: (state, action) => {
      state.tuvien = action.payload;
    },
    setXas: (state, action) => {
      state.xas = action.payload;
    },
    setTuViens: (state, action) => {
      state.tuviens = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setGlobalFilter: (state, action) => {
      state.globalFilter = action.payload;
    },
    setColumnFilters: (state, action) => {
      state.columnFilters = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getXaByAreaActive.fulfilled, (state, action) => {
        state.xas = action.payload.data.result;
      })
      .addCase(getAreas.fulfilled, (state, action) => {
        state.areas = action.payload.data.result;
      })
      .addCase(getTuViensByXaActive.fulfilled, (state, action) => {
        state.tuviens = action.payload.data.result;
      })
      .addCase(getTuVienByActive.pending, (state) => {
        state.isTuVienLoading = true;
      })
      .addCase(getTuVienByActive.fulfilled, (state, action) => {
        state.tuvien = action.payload.data;
        state.isTuVienLoading = false;
      })
      .addCase(getTuVienByActive.rejected, (state) => {
        state.isTuVienLoading = false;
      })
      .addCase(getMonksByTuVien.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMonksByTuVien.fulfilled, (state, action) => {
        const result = action.payload.data;
        state.data = result.result;
        state.rowCount = result.meta.total;
        state.isLoading = false;
        state.isRefetching = false;
        state.isError = false;
      })
      .addCase(getMonksByTuVien.rejected, (state) => {
        state.isLoading = false;
        state.isRefetching = false;
        state.isError = true;
      })
      .addCase(getTKGioiPham.pending, (state) => {
        state.isTKGioiPhamLoading = true;
      })
      .addCase(getTKGioiPham.fulfilled, (state, action) => {
        state.tkgioipham = action.payload.data;
        state.isTKGioiPhamLoading = false;
      })
      .addCase(getTKGioiPham.rejected, (state) => {
        state.isTKGioiPhamLoading = false;
      })
      .addCase(getTKGiaoPham.pending, (state) => {
        state.isTKGiaoPhamLoading = true;
      })
      .addCase(getTKGiaoPham.fulfilled, (state, action) => {
        state.tkgiaopham = action.payload.data;
        state.isTKGiaoPhamLoading = false;
      })
      .addCase(getTKGiaoPham.rejected, (state) => {
        state.isTKGiaoPhamLoading = false;
      })
      .addCase(getTKHocVi.pending, (state) => {
        state.isTKHocViLoading = true;
      })
      .addCase(getTKHocVi.fulfilled, (state, action) => {
        state.tkhocvi = action.payload.data;
        state.isTKHocViLoading = false;
      })
      .addCase(getTKHocVi.rejected, (state) => {
        state.isTKHocViLoading = false;
      });
  }
});

export const {
  setLoading,
  setGlobalFilter,
  setPagination,
  setSorting,
  setColumnFilters,
  setAreaActive,
  setXaActive,
  setTuVienActive,
  setTuVien,
  setXas,
  setTuViens
} = searchSlice.actions;

export default searchSlice.reducer;
