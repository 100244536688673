import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../api/axios';
import { API_BASE_URL } from 'config';

const initialState = {
  data: [],
  globalFilter: '',
  columnFilters: [],
  sorting: [],
  isError: false,
  isLoading: false,
  isRefetching: false,
  rowCount: 0,
  pagination: {
    pageIndex: 0,
    pageSize: 10
  },
  openAddAccount: false,
  roles: [],
  isAddUserLoading: false,
  isDeleteUserLoading: false,
  openUpdateAccount: false,
  initUpdateUser: null,
  isUpdateUserLoading: false
};

export const getUsers = createAsyncThunk('accounts/getUsers', async ({ globalFilter, pagination, sorting, columnFilters }) => {
  const { pageIndex, pageSize } = pagination;
  const url = new URL('/api/v1/users', API_BASE_URL);
  url.searchParams.set('page', `${pageIndex + 1}`);
  url.searchParams.set('perPage', `${pageSize}`);
  url.searchParams.set('query', globalFilter ?? '');
  url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
  url.searchParams.set('sortBy', `${sorting.length !== 0 ? sorting[0].id : ''}`);
  url.searchParams.set('sortOrder', `${sorting.length !== 0 ? (sorting[0].desc ? 'desc' : 'asc') : ''}`);
  try {
    const response = await axios.get(url.href);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getRoles = createAsyncThunk('accounts/getRoles', async () => {
  const url = new URL('/api/v1/roles', API_BASE_URL);
  url.searchParams.set('all', 'true');
  try {
    const response = await axios.get(url.href);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const addUser = createAsyncThunk('accounts/addUser', async (data, { rejectWithValue }) => {
  try {
    const response = await axios.post('/users/add_user', data);
    if (response) {
      return response.data;
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      return rejectWithValue(error.response.data);
    } else {
      console.error(error);
      throw error;
    }
  }
});

export const updateUser = createAsyncThunk('accounts/updateUser', async (data, { rejectWithValue }) => {
  try {
    const response = await axios.put(`/users/update_user/${data.active}`, data);
    if (response) {
      return response.data;
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      return rejectWithValue(error.response.data);
    } else {
      console.error(error);
      throw error;
    }
  }
});

export const deleteUser = createAsyncThunk('accounts/deleteUser', async (active) => {
  try {
    const response = await axios.delete(`/users/${active}`);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export const changeStatus = createAsyncThunk('accounts/changeStatus', async (active) => {
  try {
    const response = await axios.put(`/users/${active}/change_status`);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
});

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setGlobalFilter: (state, action) => {
      state.globalFilter = action.payload;
    },
    setColumnFilters: (state, action) => {
      state.columnFilters = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    setOpenAddAccount: (state, action) => {
      state.openAddAccount = action.payload;
    },
    setOpenUpdateAccount: (state, action) => {
      state.openUpdateAccount = action.payload;
    },
    setInitUpdateUser: (state, action) => {
      state.initUpdateUser = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        const result = action.payload.data;
        state.data = result.result;
        state.rowCount = result.meta.total;
        state.isLoading = false;
        state.isRefetching = false;
        state.isError = false;
      })
      .addCase(getUsers.rejected, (state) => {
        state.isLoading = false;
        state.isRefetching = false;
        state.isError = true;
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.roles = action.payload.data.result;
      })
      .addCase(addUser.pending, (state) => {
        state.isAddUserLoading = true;
      })
      .addCase(addUser.fulfilled, (state) => {
        state.isAddUserLoading = false;
      })
      .addCase(addUser.rejected, (state) => {
        state.isAddUserLoading = false;
      })
      .addCase(deleteUser.pending, (state) => {
        state.isDeleteUserLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.isDeleteUserLoading = false;
      })
      .addCase(deleteUser.rejected, (state) => {
        state.isDeleteUserLoading = false;
      })
      .addCase(updateUser.pending, (state) => {
        state.isUpdateUserLoading = true;
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.isUpdateUserLoading = false;
      })
      .addCase(updateUser.rejected, (state) => {
        state.isUpdateUserLoading = false;
      });
  }
});

export const {
  setLoading,
  setGlobalFilter,
  setPagination,
  setSorting,
  setColumnFilters,
  setOpenAddAccount,
  setOpenUpdateAccount,
  setInitUpdateUser
} = accountSlice.actions;
export default accountSlice.reducer;
