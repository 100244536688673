import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../api/axios';
import { API_BASE_URL } from 'config';

const initialState = {
  active: '',
  name: '',
  data: [],
  globalFilter: '',
  columnFilters: [],
  sorting: [],
  isError: false,
  isLoading: false,
  isRefetching: false,
  rowCount: 0,
  pagination: {
    pageIndex: 0,
    pageSize: 10
  },
  isGetLoading: false,
  xas: [],
  temple: '',
  hephais: [],
  danhxungtuviens: [],
  tuvien: null,
  isUpdateLoading: false,
  trutriIsLoading: false,
  addTuVienIsLoading: false,
  openDelete: false,
  isDelete: null,
  isLoadingDelete: false
};

export const getTemples = createAsyncThunk('temple/getTemples', async ({ globalFilter, pagination, sorting, columnFilters }) => {
  const { pageIndex, pageSize } = pagination;
  const url = new URL('/api/v1/tuviens', API_BASE_URL);
  url.searchParams.set('page', `${pageIndex + 1}`);
  url.searchParams.set('perPage', `${pageSize}`);
  url.searchParams.set('query', globalFilter ?? '');
  url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
  url.searchParams.set('sortBy', `${sorting.length !== 0 ? sorting[0].id : ''}`);
  url.searchParams.set('sortOrder', `${sorting.length !== 0 ? (sorting[0].desc ? 'desc' : 'asc') : ''}`);
  try {
    const response = await axios.get(url.href);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getXaByAreaActive = createAsyncThunk('temple/getXaByAreaActive', async ({ area_active }) => {
  const url = new URL('/api/v1/xas', API_BASE_URL);
  url.searchParams.set('area', area_active);
  url.searchParams.set('all', `${true}`);
  try {
    const response = await axios.get(url.href);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getHePhais = createAsyncThunk('temple/getHePhais', async () => {
  try {
    const response = await axios.get('/hephais');
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getDanhxungtuviens = createAsyncThunk('temple/getDanhxungtuviens', async () => {
  try {
    const response = await axios.get('/danhxungtuviens');
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getTruTris = createAsyncThunk('monks/getTruTris', async (query) => {
  const url = new URL('/api/v1/tangnis', API_BASE_URL);
  url.searchParams.set('query', query);
  url.searchParams.set('all', 'true');
  url.searchParams.set('trutri', 'true');
  try {
    const response = await axios.get(url.href);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const createTuVien = createAsyncThunk('temple/createTuVien', async (data) => {
  try {
    const response = await axios.post('/tuviens', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getTuVien = createAsyncThunk('temple/getTuVien', async (active) => {
  try {
    const response = await axios.get(`/tuviens/${active}`);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const updateTuVien = createAsyncThunk('temple/updateTuVien', async (data) => {
  try {
    const response = await axios.post(`/tuviens/${data.active}/update`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const deleteTuVien = createAsyncThunk('temple/deleteTuVien', async (active) => {
  try {
    const response = await axios.delete(`/tuviens/${active}`);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

const templeSlice = createSlice({
  name: 'temple',
  initialState,
  reducers: {
    setGlobalFilter: (state, action) => {
      state.globalFilter = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    setColumnFilters: (state, action) => {
      state.columnFilters = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setXas: (state, action) => {
      state.xas = action.payload;
    },
    setOpenDelete: (state, action) => {
      state.openDelete = action.payload;
    },
    setIsDelete: (state, action) => {
      state.isDelete = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTemples.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTemples.fulfilled, (state, action) => {
        const result = action.payload.data;
        state.data = result.result;
        state.rowCount = result.meta.total;
        state.isLoading = false;
        state.isRefetching = false;
        state.isError = false;
      })
      .addCase(getTemples.rejected, (state) => {
        state.isLoading = false;
        state.isRefetching = false;
        state.isError = true;
      })
      .addCase(getXaByAreaActive.pending, (state) => {
        state.isGetLoading = true;
      })
      .addCase(getXaByAreaActive.fulfilled, (state, action) => {
        state.xas = action.payload.data.result;
        state.isGetLoading = false;
      })
      .addCase(getXaByAreaActive.rejected, (state) => {
        state.isGetLoading = false;
      })
      .addCase(getHePhais.pending, (state) => {
        state.isGetLoading = true;
      })
      .addCase(getHePhais.fulfilled, (state, action) => {
        state.hephais = action.payload.data.result;
        state.isGetLoading = false;
      })
      .addCase(getHePhais.rejected, (state) => {
        state.isGetLoading = false;
      })
      .addCase(getTuVien.pending, (state) => {
        state.isGetLoading = true;
      })
      .addCase(getTuVien.fulfilled, (state, action) => {
        state.tuvien = action.payload.data;
        state.isGetLoading = false;
      })
      .addCase(getTuVien.rejected, (state) => {
        state.isGetLoading = false;
      })
      .addCase(updateTuVien.pending, (state) => {
        state.isUpdateLoading = true;
      })
      .addCase(updateTuVien.fulfilled, (state) => {
        state.isUpdateLoading = false;
      })
      .addCase(updateTuVien.rejected, (state) => {
        state.isUpdateLoading = false;
      })
      .addCase(createTuVien.pending, (state) => {
        state.addTuVienIsLoading = true;
      })
      .addCase(createTuVien.fulfilled, (state) => {
        state.addTuVienIsLoading = false;
      })
      .addCase(createTuVien.rejected, (state) => {
        state.addTuVienIsLoading = false;
      })
      .addCase(deleteTuVien.pending, (state) => {
        state.isLoadingDelete = true;
      })
      .addCase(deleteTuVien.fulfilled, (state) => {
        state.isLoadingDelete = false;
        state.openDelete = false;
        state.isDelete = null;
      })
      .addCase(deleteTuVien.rejected, (state) => {
        state.isLoadingDelete = false;
      })
      .addCase(getDanhxungtuviens.fulfilled, (state, action) => {
        state.danhxungtuviens = action.payload.data.result;
      });
  }
});

export const { setPagination, setLoading, setGlobalFilter, setSorting, setColumnFilters, setXas, setOpenDelete, setIsDelete } =
  templeSlice.actions;

export default templeSlice.reducer;
