import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../api/axios';
import { API_BASE_URL } from 'config';

const initialState = {
  data: [],
  globalFilter: '',
  columnFilters: [],
  sorting: [],
  isError: false,
  isLoading: false,
  isRefetching: false,
  rowCount: 0,
  pagination: {
    pageIndex: 0,
    pageSize: 10
  },
  hocvis: [],
  gioiphams: [],
  giaophams: [],
  hephais: [],
  tuviens: [],
  tuvienSelected: [],
  tuvienQuery: '',
  tuvienIsLoading: false,
  createTangNiIsLoading: false,
  tangni: null,
  updateTangNiLoading: false,
  getHocVisLoading: false,
  getTuViensLoading: false,
  getGiaoPhamsLoading: false,
  getGioiPhamsLoading: false,
  getHePhaisLoading: false,
  openDelete: false,
  isDelete: null,
  isDeleteLoading: false
};

export const getMonks = createAsyncThunk('monks/getMonks', async ({ globalFilter, pagination, sorting, columnFilters }) => {
  const { pageIndex, pageSize } = pagination;
  const url = new URL('/api/v1/tangnis', API_BASE_URL);
  url.searchParams.set('page', `${pageIndex + 1}`);
  url.searchParams.set('perPage', `${pageSize}`);
  url.searchParams.set('query', globalFilter ?? '');
  url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
  url.searchParams.set('sortBy', `${sorting.length !== 0 ? sorting[0].id : ''}`);
  url.searchParams.set('sortOrder', `${sorting.length !== 0 ? (sorting[0].desc ? 'desc' : 'asc') : ''}`);
  try {
    const response = await axios.get(url.href);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getHocVis = createAsyncThunk('monks/getHocVis', async () => {
  const url = new URL('/api/v1/hocvis', API_BASE_URL);
  url.searchParams.set('all', 'true');
  try {
    const response = await axios.get(url.href);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getGiaoPhams = createAsyncThunk('monks/getGiaoPhams', async () => {
  const url = new URL('/api/v1/giaophams', API_BASE_URL);
  url.searchParams.set('all', 'true');
  try {
    const response = await axios.get(url.href);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getGioiPhams = createAsyncThunk('monks/getGioiPhams', async () => {
  const url = new URL('/api/v1/gioiphams', API_BASE_URL);
  url.searchParams.set('all', 'true');
  try {
    const response = await axios.get(url.href);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getHePhais = createAsyncThunk('monks/getHePhais', async () => {
  const url = new URL('/api/v1/hephais', API_BASE_URL);
  url.searchParams.set('all', 'true');
  try {
    const response = await axios.get(url.href);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getTuViens = createAsyncThunk('monks/getTuViens', async (query) => {
  const url = new URL('/api/v1/tuviens', API_BASE_URL);
  url.searchParams.set('query', query);
  url.searchParams.set('all', 'true');
  try {
    const response = await axios.get(url.href);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const createTangNi = createAsyncThunk('monks/createTangNi', async (data) => {
  try {
    const response = await axios.post('/tangnis', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getTangNi = createAsyncThunk('monks/getTangNi', async (active) => {
  try {
    const response = await axios.get(`/tangnis/${active}`);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const updateTangNi = createAsyncThunk('monks/updateTangNi', async (data) => {
  try {
    const response = await axios.post(`/tangnis/${data.active}/update`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const deleteTangNi = createAsyncThunk('monks/deleteTangNi', async (active) => {
  try {
    const response = await axios.delete(`/tangnis/${active}`);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

const monkSlice = createSlice({
  name: 'monk',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setGlobalFilter: (state, action) => {
      state.globalFilter = action.payload;
    },
    setColumnFilters: (state, action) => {
      state.columnFilters = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    setTuvienSelected: (state, action) => {
      state.tuvienSelected = action.payload;
    },
    addTuvienSelected: (state, action) => {
      const index = state.tuvienSelected.findIndex((item) => item.active === action.payload.active);
      if (index !== -1) {
        state.tuvienSelected = state.tuvienSelected.filter((item) => item.active !== action.payload.active);
      } else {
        state.tuvienSelected = [...state.tuvienSelected, action.payload];
      }
    },
    setTuvienQuery: (state, action) => {
      state.tuvienQuery = action.payload;
    },
    setOpenDelete: (state, action) => {
      state.openDelete = action.payload;
    },
    setIsDelete: (state, action) => {
      state.isDelete = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMonks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMonks.fulfilled, (state, action) => {
        const result = action.payload.data;
        state.data = result.result;
        state.rowCount = result.meta.total;
        state.isLoading = false;
        state.isRefetching = false;
        state.isError = false;
      })
      .addCase(getMonks.rejected, (state) => {
        state.isLoading = false;
        state.isRefetching = false;
        state.isError = true;
      })
      .addCase(getHocVis.fulfilled, (state, action) => {
        state.hocvis = action.payload.data.result;
      })
      .addCase(getGiaoPhams.fulfilled, (state, action) => {
        state.giaophams = action.payload.data.result;
      })
      .addCase(getGioiPhams.fulfilled, (state, action) => {
        state.gioiphams = action.payload.data.result;
      })
      .addCase(getHePhais.fulfilled, (state, action) => {
        state.hephais = action.payload.data.result;
      })
      .addCase(getTuViens.pending, (state) => {
        state.tuvienIsLoading = true;
      })
      .addCase(getTuViens.fulfilled, (state, action) => {
        state.tuviens = action.payload.data.result;
        state.tuvienIsLoading = false;
      })
      .addCase(getTuViens.rejected, (state) => {
        state.tuvienIsLoading = false;
      })
      .addCase(createTangNi.pending, (state) => {
        state.createTangNiIsLoading = true;
      })
      .addCase(createTangNi.fulfilled, (state) => {
        state.createTangNiIsLoading = false;
      })
      .addCase(createTangNi.rejected, (state) => {
        state.createTangNiIsLoading = false;
      })
      .addCase(getTangNi.fulfilled, (state, action) => {
        state.tangni = action.payload.data;
      })
      .addCase(updateTangNi.pending, (state) => {
        state.updateTangNiLoading = true;
      })
      .addCase(updateTangNi.fulfilled, (state) => {
        state.updateTangNiLoading = false;
      })
      .addCase(updateTangNi.rejected, (state) => {
        state.updateTangNiLoading = false;
      })
      .addCase(deleteTangNi.pending, (state) => {
        state.isDeleteLoading = true;
      })
      .addCase(deleteTangNi.fulfilled, (state) => {
        state.isDeleteLoading = false;
        state.openDelete = false;
        state.isDelete = null;
      })
      .addCase(deleteTangNi.rejected, (state) => {
        state.isDeleteLoading = false;
      });
  }
});

export const {
  setLoading,
  setGlobalFilter,
  setPagination,
  setSorting,
  setColumnFilters,
  setTuvienQuery,
  setTuvienSelected,
  addTuvienSelected,
  setOpenDelete,
  setIsDelete
} = monkSlice.actions;
export default monkSlice.reducer;
