// type
import { Home3, Profile2User, Teacher, People } from 'iconsax-react';
// icons
const icons = {
  navigation: Home3,
  student: Profile2User,
  people: People,
  jobholder: Teacher
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const user = {
  id: 'group-user',
  title: 'Người dùng',
  icon: icons.navigation,
  type: 'group',
  children: [
    {
      id: 'users',
      title: 'Tài khoản',
      type: 'item',
      url: '/accounts',
      icon: icons.people,
      permission: ['Admin']
    }
  ]
};

export default user;
