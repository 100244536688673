// type
import { Box, Chart, Courthouse, SecurityUser, SearchNormal } from 'iconsax-react';
// icons
const icons = {
  areas: Box,
  temples: Courthouse,
  monkss: SecurityUser,
  statistical: Chart,
  search: SearchNormal
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const temple = {
  id: 'group-temples',
  title: 'Quản lý tự viện',
  icon: icons.navigation,
  type: 'group',
  children: [
    {
      id: 'areas',
      title: 'Khu vực',
      type: 'item',
      url: '/areas',
      icon: icons.areas,
      permission: ['Admin', 'Manager']
    },
    {
      id: 'temples',
      title: 'Tự viện',
      type: 'item',
      url: '/temples',
      icon: icons.temples,
      permission: ['Admin', 'Manager']
    },
    {
      id: 'monks',
      title: 'Tăng ni',
      type: 'item',
      url: '/monks',
      icon: icons.monkss,
      permission: ['Admin', 'Manager']
    },
    {
      id: 'search_infomation',
      title: 'Xem thông tin',
      type: 'item',
      url: '/search_information',
      icon: icons.search,
      permission: ['Admin', 'Manager', 'User']
    },
    {
      id: 'statistical',
      title: 'Thống kê',
      type: 'item',
      url: '/statistical',
      icon: icons.statistical,
      permission: ['Admin', 'Manager', 'User']
    }
  ]
};

export default temple;
