import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../api/axios';
import { API_BASE_URL } from 'config';

const initialState = {
  thongkes: [],
  isLoading: false,
  areas: [],
  area_active: '',
  type: 'tuvien'
};

export const getThongKe = createAsyncThunk('statistical/getThongKe', async ({ area, type }) => {
  const url = new URL(`/api/v1/areas/thongke/${type}`, API_BASE_URL);
  url.searchParams.set('area', `${area}`);
  try {
    const response = await axios.get(url.href);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getAreas = createAsyncThunk('search/getAreas', async () => {
  const url = new URL('/api/v1/areas', API_BASE_URL);
  url.searchParams.set('all', `${true}`);
  try {
    const response = await axios.get(url.href);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

const statisticalSlice = createSlice({
  name: 'statistical',
  initialState,
  reducers: {
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setAreaActive: (state, action) => {
      state.area_active = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getThongKe.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getThongKe.fulfilled, (state, action) => {
        state.thongkes = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getThongKe.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAreas.fulfilled, (state, action) => {
        state.areas = action.payload.data.result;
      });
  }
});

export const { setAreaActive, setType } = statisticalSlice.actions;

export default statisticalSlice.reducer;
