// third-party
import { combineReducers } from 'redux';

// project-imports
import menu from './menu';
import auth from './authSlice';
import area from './areaSlice';
import areaDetail from './areaDetailSlice';
import temple from './templeSlice';
import monk from './monkSlice';
import account from './accountSlice';
import search from './searchSlice';
import file from './fileSlice';
import statistical from './statisticalSlice';
// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  auth,
  area,
  areaDetail,
  temple,
  monk,
  account,
  search,
  file,
  statistical
});

export default reducers;
