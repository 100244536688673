import { Dialog } from '@mui/material';
import DialogTitleCustom from 'components/DialogTitleCustom';
import PdfComponent from 'components/PdfComponent';
import React from 'react';
import { useSelector } from 'react-redux';
import { dispatch } from 'store';
import { setFile, setOpen } from 'store/slices/fileSlice';

const PdfModal = () => {
  const { open, file } = useSelector((state) => state.file);
  const handleClose = () => {
    dispatch(setOpen(false));
    dispatch(setFile(null));
  };
  if (!file) return;
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitleCustom onClose={handleClose}>Thông tin</DialogTitleCustom>
      <PdfComponent fileUrl={file} />
    </Dialog>
  );
};

export default PdfModal;
