import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  file: null,
  open: false
};

const file = createSlice({
  name: 'file',
  initialState,
  reducers: {
    setFile: (state, action) => {
      state.file = action.payload;
    },
    setOpen: (state, action) => {
      state.open = action.payload;
    }
  }
});

export default file.reducer;

export const { setFile, setOpen } = file.actions;
