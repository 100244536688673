import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const AuthRoute = ({ component: Component }) => {
  const { isAuthenticated, currentUser } = useSelector((state) => state.auth);
  if (isAuthenticated) {
    if (currentUser.role.name === 'User') {
      return <Navigate to="/search_information" replace={true} />;
    }
    return <Navigate to="/" replace={true} />;
  }
  return <Component />;
};

export default AuthRoute;
