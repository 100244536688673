import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import axios from '../../api/axios';

const initialState = {
  isAuthenticated: false,
  isLoaded: false,
  currentUser: null
};

export const login = createAsyncThunk('auth/login', async (credentials, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/auth/login`, credentials);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    } else {
      console.error(error);
      throw error;
    }
  }
});

export const logoutUser = createAsyncThunk('auth/logoutUser', async () => {
  try {
    await axios.post(`/auth/logout`);
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export const getMe = createAsyncThunk('auth/getMe', async () => {
  try {
    const response = await axios.get(`/auth/me`);
    if (response) {
      return response.data;
    }
  } catch (error) {
    Cookies.remove('token');
    throw error;
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoaded = false;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoaded = true;
        state.isAuthenticated = true;
        state.currentUser = action.payload.data;
        Cookies.set('token', action.payload.data.accessToken);
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoaded = true;
        state.isAuthenticated = false;
        state.error = action.error.message;
      })
      .addCase(getMe.pending, (state) => {
        state.isLoaded = false;
        state.error = null;
      })
      .addCase(getMe.fulfilled, (state, action) => {
        state.isLoaded = true;
        state.isAuthenticated = true;
        state.currentUser = action.payload.data;
        Cookies.set('token', action.payload.data.accessToken);
      })
      .addCase(getMe.rejected, (state, action) => {
        state.isLoaded = true;
        state.isAuthenticated = false;
        state.error = action.error.message;
      })
      .addCase(logoutUser.fulfilled, () => {
        Cookies.remove('token');
        window.location.href = '/auth/login';
      });
  }
});

export default authSlice.reducer;
