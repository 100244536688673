import { lazy } from 'react';
import { getTangNi, setTuvienQuery, setTuvienSelected } from 'store/slices/monkSlice';
import { getAreaByActive } from 'store/slices/areaDetailSlice';
import { dispatch } from 'store';
import { getTuVien, getXaByAreaActive } from 'store/slices/templeSlice';
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import PrivateRoute from 'guards/PrivateRoute';

const Page404 = Loadable(lazy(() => import('pages/error/page404')));
const areaPage = Loadable(lazy(() => import('pages/admin/areas/areas')));
const areaDetail = Loadable(lazy(() => import('pages/admin/areas/areaDetail')));
const AccountPage = Loadable(lazy(() => import('pages/admin/accounts')));
const TemplePage = Loadable(lazy(() => import('pages/admin/temple/temple')));
const createTemple = Loadable(lazy(() => import('pages/admin/temple/createTemple')));
const updateTemple = Loadable(lazy(() => import('pages/admin/temple/updateTemple')));
const MonkPage = Loadable(lazy(() => import('pages/admin/monks/monk')));
const createMonk = Loadable(lazy(() => import('pages/admin/monks/createMonk')));
const updateMonk = Loadable(lazy(() => import('pages/admin/monks/updateMonk')));
const viewMonk = Loadable(lazy(() => import('pages/admin/monks/viewMonk')));
const TestPage = Loadable(lazy(() => import('pages/admin/test')));
const SearchInformation = Loadable(lazy(() => import('pages/admin/search/search_information')));
const SearchTangNi = Loadable(lazy(() => import('pages/admin/search/search_tangni')));
const SearchHocVi = Loadable(lazy(() => import('pages/admin/search/search_hocvi')));
const SearchGioiPham = Loadable(lazy(() => import('pages/admin/search/search_gioipham')));
const SearchGiaoPham = Loadable(lazy(() => import('pages/admin/search/search_giaopham')));
const Statistical = Loadable(lazy(() => import('pages/admin/statistical')));

const AdminRoutes = {
  path: '/',
  element: <MainLayout />,
  errorElement: <Page404 />,
  children: [
    {
      path: '',
      element: <PrivateRoute component={areaPage} withRole={['Admin', 'Manager']} />
    },
    {
      path: 'areas',
      element: <PrivateRoute component={areaPage} withRole={['Admin', 'Manager']} />
    },
    {
      path: 'areas/:active',
      loader: async ({ params }) => {
        const res = await dispatch(getAreaByActive(params.active));
        if (res && res.error) {
          throw new Response('Not Found', { status: 404 });
        }
        return res;
      },
      element: <PrivateRoute component={areaDetail} withRole={['Admin', 'Manager']} />
    },
    {
      path: 'temples',
      element: <PrivateRoute component={TemplePage} withRole={['Admin', 'Manager']} />
    },
    {
      path: 'temples/create',
      element: <PrivateRoute component={createTemple} withRole={['Admin', 'Manager']} />
    },
    {
      path: 'temples/update/:active',
      loader: async ({ params }) => {
        const res = await dispatch(getTuVien(params.active));
        if (res && res.error) {
          throw new Response('Not Found', { status: 404 });
        }
        await dispatch(getXaByAreaActive({ area_active: res.payload.data?.area_active }));

        return res;
      },
      element: <PrivateRoute component={updateTemple} withRole={['Admin', 'Manager']} />
    },
    {
      path: 'monks',
      element: <PrivateRoute component={MonkPage} withRole={['Admin', 'Manager']} />
    },
    {
      path: 'monks/create',
      element: <PrivateRoute component={createMonk} withRole={['Admin', 'Manager']} />
    },
    {
      path: 'monks/update/:active',
      loader: async ({ params }) => {
        const res = await dispatch(getTangNi(params.active));
        if (res && res.error) {
          throw new Response('Not Found', { status: 404 });
        }
        await dispatch(setTuvienQuery(res.payload.data?.tu_vien_ten));
        await dispatch(setTuvienSelected(res.payload.data?.tuviens));
        return res;
      },
      element: <PrivateRoute component={updateMonk} withRole={['Admin', 'Manager']} />
    },
    {
      path: 'monks/view/:active',
      loader: async ({ params }) => {
        const res = await dispatch(getTangNi(params.active));
        if (res && res.error) {
          throw new Response('Not Found', { status: 404 });
        }
        await dispatch(setTuvienQuery(res.payload.data?.tu_vien_ten));
        await dispatch(setTuvienSelected(res.payload.data?.tuviens));
        return res;
      },
      element: <PrivateRoute component={viewMonk} withRole={['Admin', 'Manager']} />
    },
    {
      path: 'accounts',
      element: <PrivateRoute component={AccountPage} withRole={['Admin']} />
    },
    {
      path: 'search_information',
      element: <PrivateRoute component={SearchInformation} withRole={['Admin', 'Manager', 'User']} />
    },
    {
      path: 'search_information/tangnis/:active',
      element: <PrivateRoute component={SearchTangNi} withRole={['Admin', 'Manager', 'User']} />
    },
    {
      path: 'search_information/hocvis/:active',
      element: <PrivateRoute component={SearchHocVi} withRole={['Admin', 'Manager', 'User']} />
    },
    {
      path: 'search_information/gioiphams/:active',
      element: <PrivateRoute component={SearchGioiPham} withRole={['Admin', 'Manager', 'User']} />
    },
    {
      path: 'search_information/giaophams/:active',
      element: <PrivateRoute component={SearchGiaoPham} withRole={['Admin', 'Manager', 'User']} />
    },
    {
      path: 'statistical',
      element: <PrivateRoute component={Statistical} withRole={['Admin', 'Manager', 'User']} />
    },
    {
      path: 'test',
      element: <TestPage />
    }
  ]
};

export default AdminRoutes;
