import ThemeCustomization from 'themes';
import { RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import { ThemeRoutes } from 'routes';
import { useLayoutEffect } from 'react';
import { dispatch } from 'store';
import { getMe } from 'store/slices/authSlice';

const App = () => {
  useLayoutEffect(() => {
    const fetchUser = async () => {
      await dispatch(getMe());
    };

    fetchUser();
  }, []);

  const routes = createBrowserRouter(ThemeRoutes);

  return (
    <ThemeCustomization>
      <RouterProvider router={routes} />
    </ThemeCustomization>
  );
};

export default App;
