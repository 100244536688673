// project-imports
import getColors from 'utils/getColors';
// import getShadow from 'utils/getShadow';

// ==============================|| OVERRIDES - INPUT BORDER & SHADOWS ||============================== //

function getColor({ variant, theme }) {
  const colors = getColors(theme, variant);
  const { light } = colors;

  // const shadows = getShadow(theme, `${variant}`);

  return {
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: `1.5px solid`,
      borderColor: theme.palette.secondary.light,
      backgroundColor: 'rgba(17, 25, 39, 0.04)'
    },
    '&.Mui-focused': {
      // boxShadow: shadows,
      '& .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${light}`
      }
    }
  };
}

// ==============================|| OVERRIDES - OUTLINED INPUT ||============================== //

export default function OutlinedInput(theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: 14
        },
        notchedOutline: {
          border: `1.5px solid`,
          borderColor: theme.palette.secondary.light
        },
        root: {
          '& svg': {
            color: theme.palette.secondary.main
          },
          ...getColor({ variant: 'primary', theme }),
          '&.Mui-error': {
            ...getColor({ variant: 'error', theme })
          }
        },
        inputSizeSmall: {
          padding: '10px 10px 10px 12px'
        },
        inputMultiline: {
          padding: 0
        },
        colorSecondary: getColor({ variant: 'secondary', theme }),
        colorError: getColor({ variant: 'error', theme }),
        colorWarning: getColor({ variant: 'warning', theme }),
        colorInfo: getColor({ variant: 'info', theme }),
        colorSuccess: getColor({ variant: 'success', theme })
      }
    }
  };
}
